import React from 'react';
import { styled } from 'styles';

const LayoutMain: React.FC = ({ children, ...props }) => (
  <StyledLayoutMain {...props}>{children}</StyledLayoutMain>
);

export default LayoutMain;

const StyledLayoutMain = styled.main`
  height: 100%;
`;
